
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser, updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import { CallApi } from './callApi'

export function* listCreditCardCsvRecords(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.CREDIT_CARD.FETCH_LIST + '?' + formQueryString(payload);
    let result = yield call(CallApi.GET, url, {}, true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* fetchCreditCardCsvRecord(action: any): any {
  try {
    const { id, ...queryString } = action.payload;

    const url = API_URLS.CREDIT_CARD.FETCH_DETAILS + `/${id}` + '?' + formQueryString(queryString);
    let result = yield call(CallApi.GET, url, {}, true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* creditCardUpload(action: any): any {
  try {
    // const payload = action.payload;
    const { params, formData } = action.payload;
    const url = API_URLS.CREDIT_CARD.CC_UPLOAD + '?' + formQueryString(params);
    let result = yield call(CallApi.POST, url, formData, true);
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

  export function* uploadCreditCardCsv(action: any): any {
    try {
      // const payload = action.payload;
      const { params, formData } = action.payload;
      const url = API_URLS.CREDIT_CARD.UPLOAD_CSV + '?' + formQueryString(params);;
      let result = yield call(CallApi.POST, url, formData, true);
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
  }

  export function* deleteCreditCardCsv(action: any): any {
    try {
      const { id, branch_id } = action.payload;
      const url = API_URLS.CREDIT_CARD.CC_DELETE + `/${id}` + '?branch_id=' + branch_id;
      let result = yield call(CallApi.DELETE, url, {}, true);
      action && action.callbackSuccess(result);
    } catch (e) {
      action && action.callbackError(e)
    }
  }


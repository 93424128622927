// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const ACCOUNTING_SYSTEM_API_BASE_URL = process.env.REACT_APP_ACCOUNTING_SYSTEM_API_BASE_URL
export const REVREX_APP_BASE_URL = process.env.REACT_APP_REVREX_APP_BASE_URL;


export const URLS = {
    HOME: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    BANK_TRANSACTION: '/bank-transactions',
    USERPROFILE: '/userprofile',
    SETTINGS: '/settings',
    BALANCESHEET: '/balance-sheet',
    CHARTACCOUNT: '/chart-of-accounts',
    ERROR_PAGE: '/error-page',
    USER_VERIFICATION: 'sso/user-verification',
    EXPENSE_GL_CLASSIFICATION: '/expense-gl-classification/:month/:year',
    EXPENSE_GL_CLASSIFICATION_STATUS: '/expense-gl-classification-status',
    SALES_GL_CLASSIFICATION: '/revenue-gl-classification/:month/:year',
    SALES_GL_CLASSIFICATION_STATUS: '/revenue-gl-classification-status',
    CREDIT_CARD_GL_CLASSIFICATION_STATUS: '/credit-card-gl-classification-status',
    CREDIT_CARD_GL_CLASSIFICATION: '/credit-card-gl-classification/:id',
    BANK_RECONCILE: '/bank-reconcile',
    BANK_RECONCILE_TRANSACTIONS: '/bank-reconcile-transactions/:coa',
    REVENUE_GL_CLASSIFICATION: '/deposit-gl-classification/:month/:year',
    REVENUE_GL_CLASSIFICATION_STATUS: '/deposit-gl-classification-status',
    BANK_FEED: '/bank-feed',
    SALES_TRANSACTION: '/sales-transactions',
    VIEW_REGISTER: '/view-register',
    PROFITLOSS: '/profit-loss',
    FOURTHREETHREEB: '/four-three-three-b',
    BUDGET: '/budget',
    STATEMENT_OF_CASH_FLOW: '/statement-of-cash-flow',
    BUSINESS_RATIO: '/business-ratios',
    SALES: '/sales',
    PERSONAL_BALANCE_SHEET: '/personal-balance-sheet',
    PERSONAL_INCOME_STATMENT: '/personal-income-statement',
    OPENNING_BALANCE: '/opening-balance',
}

export const API_URLS = {
    USER: {
        // LOGIN: `${API_BASE_URL}/auth/sign_in`,
        // LOGOUT: `${API_BASE_URL}/users/logout`,
        DETAILS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/auth/me`,
        USER_ACCESS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/auth/get-user-token`,
        // ACCOUNT_ALLOWANCE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/mapped/records`,
        // USER_STEP_STATUS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/step/statuses`
    },
    CHART_ACCOUNT : {
        LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts`,
        DETAILS : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts`,
        CREATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts`,
        UPDATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts`,
        AVAILABLE_ACCOUNT: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/available/accounts`,
        GL_ACCOUNT_LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/vplist`,
        LIST_TREE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/gl/accounts`,
        ACCOUNT_LEBEL : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/get/label`,
        BANK_SALE_LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/revrex/bank-sales-list`,
        BANK_SALE_UPDATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/revrex/bank-sales-list`,
        FETCH_OPENNING_BALANCE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/beginning/balances`,
        POST_OPENNING_BALANCE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/beginning/balances`,
    },
    GL_CLASSIFICATION : {
        GL_TRANS_LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts`,
        GL_RECONCILE_TRANS_LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/bank/reconciliations`,
        GL_SALES_LIST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/sales/classifications`,
        BANK_GL_UPDATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/expenses`,
        SALE_GL_UPDATE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/sales/activities`,
        REVENUE_GL_UPDATE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/deposits`,
        CLASSIFICATION_STATUS : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/completion/statuses`,
        COMPLETE_STEP : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/complete/steps`,
        GL_RECONCILE_TRANS_POST : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/bank/reconciliations`,
        GL_RECONCILE_OPENNING_BALANCE_FETCH : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/bank/reconciliations/opening/balances`,
        SKIP_BEGINNING_BALANCE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/skip/beginning/balances`,
    },
    BANK_FEED: {
        FETCH_LIST: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/bank/feeds`,
        UPDATE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/bank/feeds`
    },
    VIEW_REGISTER: {
        FETCH_LIST: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/view/registers`,
    },
    REPORT: {
        BALANCE_SHEET: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets`,
        REFRESH_BALANCE_SHEET: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/refresh/balance-sheet`,
        PROFIT_LOSSS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/pnls`,
        STATEMENT_OF_CASH_FLOW: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/cash/flows`,
        DASHBOARD_RATIO: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/ratios`,
        REPORT_DOWNLOAD_BS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/generate/reports`,
        REPORT_DOWNLOAD_BT: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/bank-sales/generate/reports`,
        TXN_REGISTER_PL: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/pnl-listing`,
    },
    TRANSACTION: {
        TRANS_LIST: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/bank-sales`,
        CREATE_TRANSACTION_ADJUSTMENT: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/adjustments`
    },
    FOUR_THREE_THREE_B : {
        DETAILS : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/business/statements/details`,
        CREATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/business/statements/business_statements`,
        UPDATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts`,
        MONTHLY_EXPENSE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/balance/sheets/form/433/populates`,
    },
    PERSONAL_BALANCE_SHEET: {
        DETAILS : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/personal/balance/sheets/details`,
        CREATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/personal/balance/sheets`,
    },
    PERSONAL_INCOME_STATMENT: {
        DETAILS : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/personal/income/statements/details`,
        CREATE : `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/personal/income/statements`,
    },
    SETTINGS: {
        // FETCH_DETAILS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets/settings/details`,
        UPDATE_ACCOUNTING: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets/settings`,
        UPDATE_SALES: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/account/charts/primary/sales`,
        FETCH_ACCOUNT_DETAILS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/auth/user/account/settings`,
        UPGRADE_TO_LATEST_GL: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/remove/opening-balances`,
    },
    BUDGET: {
        FETCH_DETAILS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets`,
        ADD: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets`,
        UPDATE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets/update`,
        PUBLISH: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/budgets/publish`,
    },
    CREDIT_CARD: {
        FETCH_LIST: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/credit-card-uploads-listing`,
        FETCH_DETAILS: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/credit-cards-listing`,
        UPLOAD_CSV: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/upload/csv/fetch-columns`,
        CC_UPLOAD: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/upload/csv/credit-cards`,
        CC_DELETE: `${ACCOUNTING_SYSTEM_API_BASE_URL}/api/v1/transaction/accounts/credit-cards`
    },
}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: 'SAGA_ACTIONS.USER.LOGIN',
        LOGOUT: 'SAGA_ACTIONS.USER.LOGOUT',
        DETAILS: 'SAGA_ACTIONS.USER.DETAILS',
        USER_ACCESS: 'SAGA_ACTIONS.USER.USER_ACCESS',
        // ACCOUNT_ALLOWANCE: 'SAGA_ACTIONS.USER.ACCOUNT_ALLOWANCE',
        // USER_STEP_STATUS: 'SAGA_ACTIONS.USER.USER_STEP_STATUS',
    },
    CHART_ACCOUNT : {
        LIST : 'SAGA_ACTIONS.CHART_ACCOUNT.LIST',
        DETAILS : 'SAGA_ACTIONS.CHART_ACCOUNT.DETAILS',
        CREATE : 'SAGA_ACTIONS.CHART_ACCOUNT.CREATE',
        UPDATE : 'SAGA_ACTIONS.CHART_ACCOUNT.UPDATE',
        AVAILABLE_ACCOUNT : 'SAGA_ACTIONS.CHART_ACCOUNT.AVAILABLE_ACCOUNT',
        GL_ACCOUNT_LIST : 'SAGA_ACTIONS.CHART_ACCOUNT.GL_ACCOUNT_LIST',
        LIST_TREE : 'SAGA_ACTIONS.CHART_ACCOUNT.GL_ACCOUNT_LIST_TREE',
        ACCOUNT_LEBEL : 'SAGA_ACTIONS.CHART_ACCOUNT.ACCOUNT_LEBEL',
        BANK_SALE_LIST : 'SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_LIST',
        BANK_SALE_UPDATE : 'SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_UPDATE',
        FETCH_OPENNING_BALANCE : 'SAGA_ACTIONS.CHART_ACCOUNT.FETCH_OPENNING_BALANCE',
        POST_OPENNING_BALANCE : 'SAGA_ACTIONS.CHART_ACCOUNT.POST_OPENNING_BALANCE',
    },
    GL_CLASSIFICATION : {
        GL_TRANS_LIST : 'SAGA_ACTIONS.GL_CLASSIFICATION.GL_TRANS_LIST',
        GL_RECONCILE_TRANS_LIST : 'SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_LIST',
        GL_SALES_LIST : 'SAGA_ACTIONS.GL_CLASSIFICATION.GL_SALES_LIST',
        BANK_GL_UPDATE : 'SAGA_ACTIONS.GL_CLASSIFICATION.BANK_GL_UPDATE',
        SALE_GL_UPDATE : 'SAGA_ACTIONS.GL_CLASSIFICATION.SALE_GL_UPDATE',
        REVENUE_GL_UPDATE : 'SAGA_ACTIONS.GL_CLASSIFICATION.REVENUE_GL_UPDATE',
        CLASSIFICATION_STATUS : 'SAGA_ACTIONS.GL_CLASSIFICATION.CLASSIFICATION_STATUS',
        COMPLETE_STEP : 'SAGA_ACTIONS.GL_CLASSIFICATION.COMPLETE_STEP',
        GL_RECONCILE_TRANS_POST : 'SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_POST',
        GL_RECONCILE_OPENNING_BALANCE_FETCH : 'SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_OPENNING_BALANCE_FETCH',
        SKIP_BEGINNING_BALANCE: 'SAGA_ACTIONS.GL_CLASSIFICATION.SKIP_BEGINNING_BALANCE',
    },
    BANK_FEED: {
        FETCH_LIST: 'SAGA_ACTIONS.BANK_FEED.FETCH_LIST',
        UPDATE: 'SAGA_ACTIONS.BANK_FEED.UPDATE',
    },
    VIEW_REGISTER: {
        FETCH_LIST: 'SAGA_ACTIONS.VIEW_REGISTER.FETCH_LIST'
    },
    REPORT: {
        BALANCE_SHEET: 'SAGA_ACTIONS.REPORT.BALANCE_SHEET',
        REFRESH_BALANCE_SHEET: 'SAGA_ACTIONS.REPORT.REFRESH_BALANCE_SHEET',
        PROFIT_LOSSS: 'SAGA_ACTIONS.REPORT.PROFIT_LOSSS',
        STATEMENT_OF_CASH_FLOW: 'SAGA_ACTIONS.REPORT.STATEMENT_OF_CASH_FLOW',
        DASHBOARD_RATIO: 'SAGA_ACTIONS.REPORT.DASHBOARD_RATIO',
        REPORT_DOWNLOAD_BS: 'SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BS',
        REPORT_DOWNLOAD_BT: 'SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BT',
        TXN_REGISTER_PL: 'SAGA_ACTIONS.REPORT.TXN_REGISTER_PL',
    },
    TRANSACTION: {
        TRANS_LIST: 'SAGA_ACTIONS.TRANSACTION.TRANS_LIST',
        CREATE_TRANSACTION_ADJUSTMENT: 'SAGA_ACTIONS.TRANSACTION.CREATE_TRANSACTION_ADJUSTMENT',
    },
    FOUR_THREE_THREE_B : {
        DETAILS : 'SAGA_ACTIONS.FOUR_THREE_THREE_B.DETAILS',
        CREATE : 'SAGA_ACTIONS.FOUR_THREE_THREE_B.CREATE',
        UPDATE : 'SAGA_ACTIONS.FOUR_THREE_THREE_B.UPDATE',
        MONTHLY_EXPENSE : 'SAGA_ACTIONS.FOUR_THREE_THREE_B.MONTHLY_EXPENSE',
    },
    PERSONAL_BALANCE_SHEET: {
        DETAILS : 'SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.DETAILS',
        CREATE : 'SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.CREATE',
    },
    PERSONAL_INCOME_STATMENT: {
        DETAILS : 'SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.DETAILS',
        CREATE : 'SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.CREATE',
    },
    SETTINGS: {
        // FETCH_DETAILS: 'SAGA_ACTIONS.SETTINGS.FETCH_DETAILS',
        UPDATE_ACCOUNTING: 'SAGA_ACTIONS.SETTINGS.UPDATE_ACCOUNTING',
        UPDATE_SALES: 'SAGA_ACTIONS.SETTINGS.UPDATE_SALES',
        FETCH_ACCOUNT_DETAILS: 'SAGA_ACTIONS.SETTINGS.FETCH_ACCOUNT_DETAILS',
        UPGRADE_TO_LATEST_GL: 'SAGA_ACTIONS.SETTINGS.UPGRADE_TO_LATEST_GL',
    },
    BUDGET: {
        FETCH_DETAILS: 'SAGA_ACTIONS.BUDGET.FETCH_DETAILS',
        ADD: 'SAGA_ACTIONS.BUDGET.ADD',
        UPDATE: 'SAGA_ACTIONS.BUDGET.UPDATE',
        PUBLISH: 'SAGA_ACTIONS.BUDGET.PUBLISH',
    },
    CREDIT_CARD: {
        FETCH_LIST: 'SAGA_ACTIONS.CREDIT_CARD.FETCH_LIST',
        FETCH_DETAILS: 'SAGA_ACTIONS.CREDIT_CARD.FETCH_DETAILS',
        UPLOAD_CSV: 'SAGA_ACTIONS.CREDIT_CARD.UPLOAD_CSV',
        CC_UPLOAD: 'SAGA_ACTIONS.CREDIT_CARD.CC_UPLOAD',
        CC_DELETE: 'SAGA_ACTIONS.CREDIT_CARD.CC_DELETE',
    }
}

export const CA_REPORT = [{
    label: 'BS',
    value: 'BS'
}, {
    label: 'PL',
    value: 'PL'
}]

export const CA_ACC_TYPE = [{
    label: 'Debit',
    value: 'D'
}, {
    label: 'Credit',
    value: 'C'
}]

export const CC_AMOUNT_TYPE = [{
    label: 'Credit card charge amount negative',
    value: 1
}, {
    label: 'Credit card charge amount positive',
    value: 2
}, {
    label: 'Separate Column To Define Transaction Type',
    value: 3
}]


export const CC_DATE_FORMAT = [{
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY"
}, {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY"
}, {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY"
}, {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY"
}, {
    label: "MM/DD/YYYY & MM-DD-YYYY",
    value: "MM/DD/YYYY&MM-DD-YYYY"
}, {
    label: "DD/MM/YYYY & DD-MM-YYYY",
    value: "DD/MM/YYYY&DD-MM-YYYY"
}, {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD"
}, {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD"
}, {
    label: "YYYY/MM/DD & YYYY-MM-DD",
    value: "YYYY/MM/DD&YYYY-MM-DD"
}]

export const MONTHS = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
]

export const BUDGET_BASED = [{
    value: 1, label: 'Calendar Year'
}, {
    value: 2, label: 'Fiscal Year'
}]

export const MENUS = {
    DASHBOARD: ['dashboard'],
    Classification: ['sales-gl-classification', 'revenue-gl-classification', 'expense-gl-classification', 'expense-gl-classification-status', 'deposit-gl-classification-status', 'revenue-gl-classification-status', 'bank-reconcile'],
    CHART_OF_ACCOUNT: ['chart-of-accounts', 'bank-feed', 'view-register', 'budget', 'opening-balance'],
    // BUDGET: ['budget'],
    FORMS: ['four-three-three-b', 'personal-balance-sheet', 'personal-income-statement'],
    REPORTS: ['bank-transactions', 'sales-transactions', 'balance-sheet', 'profit-loss', 'statement-of-cash-flow', 'business-ratios'],
    // BUSINESS_RATIOS: ['business-ratios'],
}

export const GL_VP = {
    BANK_ACCOUNT: {
        account: [1002]
    },
    SALES_ACTIVITY: {
        account: [6001, 6100, 6700, 6987]
    },
    CREDIT_CARD: {
        account: [4002, 4050, 4200]
    },
    DEBIT_CARD: {
        account: [1002, 1014, 1051, 1200, 1946, 2001, 3000],
        exclude_accounts: [1002,1014, 1151, 1200, 1946, 2001, 3000, 4002, 4050, 4200, 4300, 5001, 5200]
    },
}
export const STATES = [
    { country_id: 1, state: 'Alabama', abbreviation: 'AL' },
    { country_id: 1, state: 'Alaska', abbreviation: 'AK' },
    { country_id: 1, state: 'Arizona', abbreviation: 'AZ' },
    { country_id: 1, state: 'Arkansas', abbreviation: 'AR' },
    { country_id: 1, state: 'California', abbreviation: 'CA' },
    { country_id: 1, state: 'Colorado', abbreviation: 'CO' },
    { country_id: 1, state: 'Connecticut', abbreviation: 'CT' },
    { country_id: 1, state: 'Delaware', abbreviation: 'DE' },
    { country_id: 1, state: 'Florida', abbreviation: 'FL' },
    { country_id: 1, state: 'Georgia', abbreviation: 'GA' },
    { country_id: 1, state: 'Hawaii', abbreviation: 'HI' },
    { country_id: 1, state: 'Idaho', abbreviation: 'ID' },
    { country_id: 1, state: 'Illinois', abbreviation: 'IL' },
    { country_id: 1, state: 'Indiana', abbreviation: 'IN' },
    { country_id: 1, state: 'Iowa', abbreviation: 'IA' },
    { country_id: 1, state: 'Kansas', abbreviation: 'KS' },
    { country_id: 1, state: 'Kentucky', abbreviation: 'KY' },
    { country_id: 1, state: 'Louisiana', abbreviation: 'LA' },
    { country_id: 1, state: 'Maine', abbreviation: 'ME' },
    { country_id: 1, state: 'Maryland', abbreviation: 'MD' },
    { country_id: 1, state: 'Massachusetts', abbreviation: 'MA' },
    { country_id: 1, state: 'Michigan', abbreviation: 'MI' },
    { country_id: 1, state: 'Minnesota', abbreviation: 'MN' },
    { country_id: 1, state: 'Mississippi', abbreviation: 'MS' },
    { country_id: 1, state: 'Missouri', abbreviation: 'MO' },
    { country_id: 1, state: 'Montana', abbreviation: 'MT' },
    { country_id: 1, state: 'Nebraska', abbreviation: 'NE' },
    { country_id: 1, state: 'Nevada', abbreviation: 'NV' },
    { country_id: 1, state: 'New Hampshire', abbreviation: 'NH' },
    { country_id: 1, state: 'New Jersey', abbreviation: 'NJ' },
    { country_id: 1, state: 'New Mexico', abbreviation: 'NM' },
    { country_id: 1, state: 'New York', abbreviation: 'NY' },
    { country_id: 1, state: 'North Carolina', abbreviation: 'NC' },
    { country_id: 1, state: 'North Dakota', abbreviation: 'ND' },
    { country_id: 1, state: 'Ohio', abbreviation: 'OH' },
    { country_id: 1, state: 'Oklahoma', abbreviation: 'OK' },
    { country_id: 1, state: 'Oregon', abbreviation: 'OR' },
    { country_id: 1, state: 'Pennsylvania', abbreviation: 'PA' },
    { country_id: 1, state: 'Rhode Island', abbreviation: 'RI' },
    { country_id: 1, state: 'South Carolina', abbreviation: 'SC' },
    { country_id: 1, state: 'South Dakota', abbreviation: 'SD' },
    { country_id: 1, state: 'Tennessee', abbreviation: 'TN' },
    { country_id: 1, state: 'Texas', abbreviation: 'TX' },
    { country_id: 1, state: 'Utah', abbreviation: 'UT' },
    { country_id: 1, state: 'Vermont', abbreviation: 'VT' },
    { country_id: 1, state: 'Virginia', abbreviation: 'VA' },
    { country_id: 1, state: 'Washington', abbreviation: 'WA' },
    { country_id: 1, state: 'West Virginia', abbreviation: 'WV' },
    { country_id: 1, state: 'Wisconsin', abbreviation: 'WI' },
    { country_id: 1, state: 'Wyoming', abbreviation: 'WY' },
    { country_id: 2, state: 'Alberta', abbreviation: 'AB' },
    { country_id: 2, state: 'British Columbia', abbreviation: 'BC' },
    { country_id: 2, state: 'Manitoba', abbreviation: 'MB' },
    { country_id: 2, state: 'New Brunswick', abbreviation: 'NB' },
    { country_id: 2, state: 'Newfoundland and Labrador', abbreviation: 'NL' },
    { country_id: 2, state: 'Northwest Territories', abbreviation: 'NT' },
    { country_id: 2, state: 'Nova Scotia', abbreviation: 'NS' },
    { country_id: 2, state: 'Nunavut', abbreviation: 'NU' },
    { country_id: 2, state: 'Ontario', abbreviation: 'ON' },
    { country_id: 2, state: 'Prince Edward Island', abbreviation: 'PE' },
    { country_id: 2, state: 'Quebec', abbreviation: 'QC' },
    { country_id: 2, state: 'Saskatchewan', abbreviation: 'SK' },
    { country_id: 2, state: 'Yukon', abbreviation: 'YT' },
    { country_id: 3, state: 'Aguascalientes', abbreviation: 'AG' },
    { country_id: 3, state: 'Baja California', abbreviation: 'BC' },
    { country_id: 3, state: 'Baja California Sur', abbreviation: 'BS' },
    { country_id: 3, state: 'Campeche', abbreviation: 'CM' },
    { country_id: 3, state: 'Chiapas', abbreviation: 'CS' },
    { country_id: 3, state: 'Chihuahua', abbreviation: 'CH' },
    { country_id: 3, state: 'Coahuila', abbreviation: 'CO' },
    { country_id: 3, state: 'Colima', abbreviation: 'CL' },
    { country_id: 3, state: 'Durango', abbreviation: 'DG' },
    { country_id: 3, state: 'Guanajuato', abbreviation: 'GT' },
    { country_id: 3, state: 'Guerrero', abbreviation: 'GR' },
    { country_id: 3, state: 'Hidalgo', abbreviation: 'HG' },
    { country_id: 3, state: 'Jalisco', abbreviation: 'JA' },
    { country_id: 3, state: 'Mexico', abbreviation: 'EM' },
    { country_id: 3, state: 'Michoacan', abbreviation: 'MI' },
    { country_id: 3, state: 'Morelos', abbreviation: 'MO' },
    { country_id: 3, state: 'Nayarit', abbreviation: 'NA' },
    { country_id: 3, state: 'Nuevo Leon', abbreviation: 'NL' },
    { country_id: 3, state: 'Oaxaca', abbreviation: 'OA' },
    { country_id: 3, state: 'Puebla', abbreviation: 'PU' },
    { country_id: 3, state: 'Queretaro', abbreviation: 'QT' },
    { country_id: 3, state: 'Quintana Roo', abbreviation: 'QR' },
    { country_id: 3, state: 'San Luis Potosi', abbreviation: 'SL' },
    { country_id: 3, state: 'Sinaloa', abbreviation: 'SI' },
    { country_id: 3, state: 'Sonora', abbreviation: 'SO' },
    { country_id: 3, state: 'Tabasco', abbreviation: 'TB' },
    { country_id: 3, state: 'Tamaulipas', abbreviation: 'TL' },
    { country_id: 3, state: 'Tlaxcala', abbreviation: 'TM' },
    { country_id: 3, state: 'Veracruz', abbreviation: 'VE' },
    { country_id: 3, state: 'Yucatan', abbreviation: 'YU' },
    { country_id: 3, state: 'Zacatecas', abbreviation: 'ZA' },
    { country_id: 4, state: 'Andhra Pradesh', abbreviation: 'AD' },
    { country_id: 4, state: 'Arunachal Pradesh', abbreviation: 'AR' },
    { country_id: 4, state: 'Assam', abbreviation: 'AS' },
    { country_id: 4, state: 'Bihar', abbreviation: 'BR' },
    { country_id: 4, state: 'Chattisgarh', abbreviation: 'CG' },
    { country_id: 4, state: 'Delhi', abbreviation: 'DL' },
    { country_id: 4, state: 'Goa', abbreviation: 'GA' },
    { country_id: 4, state: 'Gujarat', abbreviation: 'GJ' },
    { country_id: 4, state: 'Haryana', abbreviation: 'HR' },
    { country_id: 4, state: 'Himachal Pradesh', abbreviation: 'HP' },
    { country_id: 4, state: 'Jammu and Kashmir', abbreviation: 'JK' },
    { country_id: 4, state: 'Jharkhand', abbreviation: 'JH' },
    { country_id: 4, state: 'Karnataka', abbreviation: 'KA' },
    { country_id: 4, state: 'Kerala', abbreviation: 'KL' },
    { country_id: 4, state: 'Lakshadweep Islands', abbreviation: 'LD' },
    { country_id: 4, state: 'Madhya Pradesh', abbreviation: 'MP' },
    { country_id: 4, state: 'Maharashtra', abbreviation: 'MH' },
    { country_id: 4, state: 'Manipur', abbreviation: 'MN' },
    { country_id: 4, state: 'Meghalaya', abbreviation: 'ML' },
    { country_id: 4, state: 'Mizoram', abbreviation: 'MZ' },
    { country_id: 4, state: 'Nagaland', abbreviation: 'NL' },
    { country_id: 4, state: 'Odisha', abbreviation: 'OD' },
    { country_id: 4, state: 'Pondicherry', abbreviation: 'PY' },
    { country_id: 4, state: 'Punjab', abbreviation: 'PB' },
    { country_id: 4, state: 'Rajasthan', abbreviation: 'RJ' },
    { country_id: 4, state: 'Sikkim', abbreviation: 'SK' },
    { country_id: 4, state: 'Tamil Nadu', abbreviation: 'TN' },
    { country_id: 4, state: 'Telangana', abbreviation: 'TS' },
    { country_id: 5, state: 'New South Wales', abbreviation: 'xx' },
    { country_id: 5, state: 'Queensland', abbreviation: 'xx' },
    { country_id: 5, state: 'South Australia', abbreviation: 'xx' },
    { country_id: 5, state: 'Tasmania', abbreviation: 'xx' },
    { country_id: 5, state: 'Victoria', abbreviation: 'xx' },
    { country_id: 5, state: 'Western Australia', abbreviation: 'xx' },
];

export const SELECT_CUSTOM_STYLE = ( disabledColor: string ) => ({
    option: (provided: any) => ({
        ...provided,
        whiteSpace: 'normal', // Allow text to wrap to the next line
        wordWrap: 'break-word', // Break long words
    }),
    menu: (provided: any) => ({
        ...provided,
        width: 'auto', // Adjust the width of the menu to fit the content
        minWidth: '100%', // Ensure the menu is at least as wide as the select input
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        backgroundColor: state.isDisabled ? '#fff' : disabledColor //'#d1ffbd'
    }),
    singleValue: (provided: any) => ({
        ...provided,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? 'hsl(0, 0%, 50%)' : 'black'
    }),
});


import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function glClassificationApi() {

    const callApi = useApiCall();

    const glTransactionlist = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.GL_TRANS_LIST, data, onSuccess, onError);
    }
    const glReconcileTransactionlist = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_LIST, data, onSuccess, onError);
    }
    const glSaleslist = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.GL_SALES_LIST, data, onSuccess, onError);
    }
    const bankGlUpdate = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.BANK_GL_UPDATE, data, onSuccess, onError);
    }
    const saleGlUpdate = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.SALE_GL_UPDATE, data, onSuccess, onError);
    }
    const revenueGlUpdate = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.REVENUE_GL_UPDATE, data, onSuccess, onError);
    }
    const classificationListStatus = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.CLASSIFICATION_STATUS, data, onSuccess, onError);
    }
    const completeStep = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.COMPLETE_STEP, data, onSuccess, onError);
    }
    const glReconcileTransactionSubmit = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_POST, data, onSuccess, onError);
    }
    const glReconcileOpeningBalanceFetch = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_OPENNING_BALANCE_FETCH, data, onSuccess, onError);
    }
    const skipBeginningBalance = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.GL_CLASSIFICATION.SKIP_BEGINNING_BALANCE, data, onSuccess, onError);
    }
    
    return {
        glTransactionlist,
        glReconcileTransactionlist,
        glSaleslist,
        bankGlUpdate,
        saleGlUpdate,
        revenueGlUpdate,
        classificationListStatus,
        completeStep,
        glReconcileTransactionSubmit,
        glReconcileOpeningBalanceFetch,
        skipBeginningBalance
    }
}
import React, { useEffect, useState, useRef, ReactNode, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import toast from 'react-hot-toast';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncSelect from 'react-select/async';
import { SelectInstance } from 'react-select';

import { currencyFormat } from '../../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import Card from 'react-bootstrap/Card';
import { CreditCardClassifyFormsI, RevenueGlClassificationFormI } from '../../../resources/form-props';
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { useCreditCardApi } from '../../../resources/hooks/api/creditCardApiHook';
import { CreditCardClassificationValidate } from '../../../resources/form-validator';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { transTableDataApi } from '../../../resources/hooks/api/transApiHook';
import { getBranch } from '../../../store/user/selectors'

// import '../GlClassification/GlClassification.css'
import { GL_VP, MONTHS, SELECT_CUSTOM_STYLE } from '../../../resources/constants';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { lastYears, calculateMaxHeightContent, usdCurrencyFormat } from '../../../resources/functions';
import { start } from 'repl';
import Pagination from '@mui/material/Pagination';
import { set } from 'date-fns';

interface Props {

}

const CreditCardGlClassification: React.FC<Props> = () => {

    const { glReconcileTransactionSubmit, glReconcileTransactionlist } = glClassificationApi();
    const { fetchCreditCardCsvDetails } = useCreditCardApi();
    const { createTransactionAdjustment } = transTableDataApi()
    const { glAccountsList } = useChartAccountApi();
    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);

    const routeParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>({
        start_date: new Date(),
        end_date: new Date(),
        account_name: '',
        account: '',
        account_id: 0,
        file_name: '',
        classify_status: false,
        total_records: 1000,
        records: []
    });
    const [reconciliationData, setReconciliationData] = useState<any>(null);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));
    const [searchParams, setSearchParams] = useState<any>({
        id: null,
        page: 1,
        per_page: 100
    });
    const [ dropdownOptions, setDropdownOptions ] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const checkAllRef = useRef<HTMLInputElement>(null);

    const { register, control, trigger, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<CreditCardClassifyFormsI>({
        defaultValues: {
            classifications: []
        },
        resolver: yupResolver(CreditCardClassificationValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "classifications"
    });

    useEffect(() => {
        if (routeParams['id'] && !isNaN(+routeParams['id'])) {
            setSearchParams((prevState: any) => ({
                ...prevState,
                id: routeParams['id']
            }))
        }
    }, [routeParams])

    useEffect(() => {
        if (searchParams['id']) {
            fetchTransactionList(+searchParams['id']);
            filterDebitAccountList('');
        }
    }, [searchParams])

    useEffect(() => {
        records['records'].map((record: any, index: number) => {
            formAppend({
                formIndex: index,
                checked: !(record['duplicate'] || record['posted']),
                id: record['id'],
                description: record['description'],
                transaction_at: record['transaction_at'],
                adjustment_debit: record['amount'],
                adjustment_credit: record['amount'],
                adjustment_master_debit_account_id: null,
                adjustment_master_credit_account_id: null,
                duplicate: !!record['duplicate'],
                posted: !!record['posted']
            })
        })
    }, [records['records']])

    useEffect(() => {
        if (reconciliationData) {
            const data: any = {};

            data['beginningBalance'] = reconciliationData.opening_balance_amount
            data['endingBalance'] = reconciliationData.closing_balance_amount
            data['cleared_balance_amount'] = reconciliationData.cleared_balance_amount
            data['difference_amount'] = reconciliationData.difference_amount

            // setFormParams((prevState: any) => ({
            //     ...prevState,
            //     ...data
            // }))
        }

    }, [reconciliationData])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })
    useEffect(() => {
        _handleOptionsFetching();
    }, [])

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
        if (fields.length == records['records'].length) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = true;
            }
        }
    }, [fields])

    const fetchTransactionList = (id: number) => {
        const params: any = {
            id,
            branch_id: +branchData['id'],
            "page": searchParams['page'],
            "per_page": searchParams['per_page'],
        }

        setIsLoading(true);
        fetchCreditCardCsvDetails(params, (message: string, resp: any) => {
            setRecords({
                start_date: resp.data.data.start_date,
                end_date: resp.data.data.end_date,
                account_name: resp.data.data.account_name,
                account: resp.data.data.account,
                account_id: resp.data.data.account_id,
                file_name: resp.data.data.file_name,
                classify_status: !!resp.data.data.classify_status,
                total_records: resp.data.data.total,
                records: resp.data.data.items
            });
            setIsLoading(false);

        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const fechDebitAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_not_in'] = GL_VP.DEBIT_CARD.exclude_accounts.join(',');
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const _handleOptionsFetching = async () => {
        const data = await fechDebitAccountList('');
        setDropdownOptions(data)
    }

    const filterDebitAccountList = async (inputValue: string) => {
        const data = await fechDebitAccountList(inputValue)
        return data;
    };


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            records['records'].map((record: any, index: number) => {
                if ( !(record['duplicate'] || record['posted']) ) {
                    setValue(`classifications.${index}.checked`, e.target.checked)
                }
            })
        }
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`classifications.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = records['records'].filter((record: any, index: number) => getValues(`classifications.${index}.checked`) === true);

            if (checkedRecords.length == records['records'].length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = records['records'].filter((record: any, index: number) => getValues(`classifications.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }

    const processData = (data: any) => {
        const toRemoveItems: number[] = [];
        const selectedRecords = data['classifications']
            .map((d: any, index: number) => {
                if (d['checked']) {
                    toRemoveItems.push(index);
                    return {
                        id: d.id,
                        description: d.description,
                        transaction_at: d.transaction_at,
                        adjustment_debit: d.adjustment_debit,
                        adjustment_credit: d.adjustment_credit,
                        adjustment_master_debit_account_id: d.adjustment_master_debit_account_id && +d.adjustment_master_debit_account_id.value || 0,
                        adjustment_master_credit_account_id: +records['account_id'],
                    };
                } else {
                    return null;
                }
            })
            .filter((e: any) => e); // Filter out null values
        if ( selectedRecords.length > 0) {
            postData(selectedRecords, toRemoveItems);
        } else {
            toast.error('Please select at least one record to post');
            return;
        }
        
    };
    const postData = (records: any, toRemoveItems: number[]) => {
        setIsLoading(true);

        createTransactionAdjustment({ params: records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.success(message)

            reset({
                classifications: []
            })

            fetchTransactionList(+searchParams['id']);

            

            // formRemove(toRemoveItems)
            // if (records.length == fields.length) {
            //     navigate(-1);
            // }
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const handleSelectChange = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
                const nextSelect = selectRefs.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };

    const onChangePage = (event: any, page: number) => {
        setSearchParams((prevState: any) => ({
            ...prevState,
            page: page
        }))
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">
                                {'CC Transaction - ' + moment(records['start_date']).format('Do MMM YYYY') + ' to ' + moment(records['end_date']).format('Do MMM YYYY') + ' - ' + records['account_name'] + ' (' + records['account'] + ')'}
                            </h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Form>
                            {
                                (fields.length > 0 && !records['classify_status']) && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>Post</button>
                                    </div> || <></>
                                )
                            }

                            <br></br>
                            {/* <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}> */}
                            <div className="table-responsive list-table table-scroll">
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        {/* <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" disabled={fields.length > 0 && fields[0].id ? true : false} onChange={handleCheckAllChange} /> */}
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} disabled={records['classify_status']} />
                                                    </label>
                                                </div>
                                            </th>
                                            <th className="text-center w-5">Date</th>
                                            <th className="text-start">Description </th>
                                            <th className="text-start">Debit GL Account </th>
                                            <th className="text-start w-5">Posted </th>
                                            <th className="text-end w-5">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => (
                                                    <tr className="align-middle" key={data['id']}>
                                                        <td className="text-center">
                                                            <div className="form-check ">
                                                                <label >
                                                                    <input className="form-check-input wh-20 checkbox" {...register(`classifications.${index}.checked`)}
                                                                        name={`revenues.${index}.checked`}
                                                                        defaultChecked={fields[index]['checked']}
                                                                        disabled={fields[index].duplicate || fields[index].posted}
                                                                        type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className={fields[index].duplicate ? "text-danger text-start" : "text-start"}>
                                                            <strong>{moment(fields[index].transaction_at).format('Do MMM YYYY')}</strong>
                                                        </td>
                                                        <td className={fields[index].duplicate ? "text-danger text-start text-truncate" : "text-start text-truncate"} style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>
                                                        <td className="text-start">
                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                <Controller
                                                                    control={control}
                                                                    name={`classifications.${index}.adjustment_master_debit_account_id`}
                                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                                        <AsyncSelect
                                                                            ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                            isClearable={true}
                                                                            placeholder={'Please select GL acc'}
                                                                            defaultOptions={dropdownOptions}
                                                                            loadOptions={filterDebitAccountList}
                                                                            onChange={(e: any) => {
                                                                                onChange(e);
                                                                                handleSelectChange(index, e);
                                                                            }}
                                                                            className='vp-dropdown'
                                                                            menuPosition='fixed'
                                                                            value={value}
                                                                            defaultValue={value}
                                                                            styles={selectCustomStyle}
                                                                            components={{ Option: SelectCustomOption }}
                                                                            isDisabled={fields[index].posted || fields[index].duplicate}
                                                                        />
                                                                    )}
                                                                />
                                                                {
                                                                    !!fields[index].adjustment_master_debit_account_id && <input {...register(`classifications.${index}.adjustment_master_debit_account_id`)} type="hidden" />
                                                                }
                                                            </Form.Group>
                                                            {
                                                                errors && errors.classifications && errors.classifications[index] && errors.classifications[index]?.adjustment_master_debit_account_id && errors.classifications[index]?.adjustment_master_debit_account_id?.message && (
                                                                    <Form.Text className="text-danger d-flex">
                                                                        <strong>
                                                                            {errors.classifications[index]?.adjustment_master_debit_account_id?.message as ReactNode || ''}
                                                                        </strong>
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </td>
                                                        <td className={fields[index].duplicate ? "text-danger text-start" : "text-start"}>{fields[index].posted ? 'Yes' : 'No'}</td>
                                                        <td className={fields[index].duplicate ? "text-danger text-end" : "text-end"}>{usdCurrencyFormat(fields[index].adjustment_credit)}</td>
                                                    </tr>
                                                )) : <tr>
                                                    <td colSpan={5} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Pagination count={Math.ceil(records['total_records'] / searchParams['per_page'])} showFirstButton showLastButton onChange={onChangePage} />
                    </div>
                    
                </div>
            </div>
        </div>

    )
}

export default CreditCardGlClassification;
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom'

import plusIcon from '../../assets/images/plus-icon-w.svg';
import expandIcon from '../../assets/images/expand-svgrepo-com-w.svg';
import collapseIcon from '../../assets/images/collapse-alt-svgrepo-com-w.svg';
import ChartAccountModal from '../../components/Modal/ChartOfAccount/ChartAccountModal'
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import { glClassificationApi } from '../../resources/hooks/api/glClassificationApi';
import { getAccountSettings } from '../../store/settings/selectors'
import CaRecod from './caRecord';
import { getBranch } from '../../store/user/selectors'
import { URLS } from '../../resources/constants';

interface Props {

}

const ChartOfAccounts: React.FC<Props> = () => {

  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedRef, setSelectedRef] = useState<any>(null);

  const branchData = useSelector(getBranch);
  const navigate = useNavigate();

  const { completeStep } = glClassificationApi();
  const { getList, getDetails } = useChartAccountApi();
  const _getAccountSettings = useSelector(getAccountSettings);

  const [recordList, setRecordList] = useState<any>([]);

  useEffect(() => {
    fetchList();
  }, [])
  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    if (selectedRef) {
      selectedRef.current.click()
    }
    if (selectedRecord) {
      setSelectedRecord(null);
    } else {
      setRecordList([])
    }

    fetchList();
    setShowModal(false);
    setSelectedRecord(null);
    setSelectedRef(null);
  };

  const expandCollapseToggle = () => {
    setIsExpandAll(!isExpandAll)
  }

  const handleEditRecord = async (id: number) => {
    const data = await fetchAccountDetails(id);
    if (data) {
      setSelectedRecord(data);
      showModal();
    }
  }

  const fetchAccountDetails = (id = 0): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const params = {
        id,
        branch_id: +branchData['id']
      }
      getDetails(params, (message: string, resp: any) => {
        return resolve(resp.data.data)
      }, (message: string, resp: any) => {
        toast.error(message);
        return resolve(null);
      })
    })
  }

  const fetchList = () => {
    setIsLoading(true);
    getList({ "page": 1, "per_page": 1000, "id": 0, branch_id: +branchData['id'], all: 1 }, (message: string, resp: any) => {
      setIsLoading(false);
      setRecordList(resp.data.data);
    }, (message: string, resp: any) => {
      setIsLoading(false);
      toast.error(message);
    })
  }

  const completeCurrentStep = () => {
    setIsLoading(true);
    completeStep({ branch_id: +branchData['id'], type: 1 }, (message: string, resp: any) => {
      setIsLoading(false);
      navigate(URLS.DASHBOARD)
    }, (message: string, resp: any) => {
      setIsLoading(false);
      toast.error(message);
    })
  }

  return (

    <>
      <div className="main-container flex-grow-1">
        <div className="container-fluid">
          <div className="page-title pb-4 pt-3">
            <div className="row align-items-center">
              <div className="col-sm-6 align-items-center d-flex">
                <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Chart of Accounts</h1>
                {/* <div className="two-btn-wrap ms-2">
                  <Link to="#"><img src={commentEditIcon} alt="" /></Link>
                  <Link to="#"><img src={playReviewIcon} alt="" /></Link>

                </div> */}
              </div>
              <div className="col-sm-6 align-items-center d-flex justify-content-end">
                <div className="dropdown add-new-dropdown d-flex">
                  {
                    !_getAccountSettings.is_coa_modified && 
                    (<Link to="javascript:void(0)" className="d-flex gap-2 align-items-center btn btn-cancel" onClick={completeCurrentStep}>
                      <span>Click here to skip this step</span>
                    </Link>) || <></>
                  }
                  &nbsp;&nbsp;
                  <Link to="javascript:void(0)" className="d-flex gap-2 align-items-center btn btn-primary" onClick={expandCollapseToggle}>
                    {isExpandAll ? <span> <img src={collapseIcon} alt="" style={{ height: "20px" }} /> Collapse All </span> : <span> <img src={expandIcon} alt="" style={{ height: "20px" }} /> &nbsp;&nbsp; Expand All </span>}
                  </Link> &nbsp;&nbsp;
                  <Link to="javascript:void(0)" className="d-flex gap-2 align-items-center btn btn-primary" onClick={showModal}>
                    <span><img src={plusIcon} alt="" /> &nbsp;&nbsp;New Account</span>
                  </Link>
                  {/* <ul className="dropdown-menu dropdown-menu-end add-new-dropdown-box">
                    <li><Link className="dropdown-item" to="javascript:void(0)" onClick={showModal}>Add New Account</Link></li>
                    <li><Link className="dropdown-item" to="javascript:void(0)">New Account List</Link></li>
                  </ul> */}
                </div>
              </div>

            </div>
            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
              <div className="loader"></div>
            </div>
          </div>

          <div className=" executive-dashboard">
            <div className="dashboard-top-part">

              <div className="table-responsive list-table">
                <div className="module-permission-table-wrapper">
                  <div className="module-permission-table-row th d-flex">
                    <div className="module-permission-col">
                      Module
                    </div>
                    <div className="module-permission-col">
                      Account Number
                    </div>
                    <div className="module-permission-col text-start">
                      Description
                    </div>
                    <div className="module-permission-col text-end">
                      Action
                    </div>
                    {/* <div className="module-permission-col">
                                    View
                                  </div>
                                  <div className="module-permission-col">
                                    Create
                                  </div>
                                  <div className="module-permission-col">
                                    Edit
                                  </div>
                                  <div className="module-permission-col">
                                    Delete
                                  </div>
                                  <div className="module-permission-col">
                                    Import
                                  </div>
                                  <div className="module-permission-col">
                                    Export
                                  </div> */}
                  </div>
                  <div className="tree">
                    {
                      recordList.length && recordList.map((d: any, i: number) => (
                        <CaRecod record={d} key={i} handleEditRecord={handleEditRecord} isExpandAll={isExpandAll} />
                      )) || <></>
                    }
                  </div>
                  {/* {record.length !== 0 ? (

                    record.map((d: any, i: number) => (
                      <div className="role_multiclopasable" key={i}>
                        <div className="module-permission-table-row td d-flex" >
                          <div className="module-permission-col" data-bs-toggle="collapse" data-bs-target={`#collapseExample_${d.id}`} onClick={getSubVal} id={d.id} role="button" aria-expanded="false" aria-controls="collapseExample">
                            {d.description}
                          </div>

                        </div>
                        {d.id == clickid ? (<SubList subRecord={subRecord} sl_id={d.id} getSubVal={getSubVal}></SubList>) : ""}

                      </div>

                    ))

                  ) : (<p className='data-not-found'><b>DATA NOT FOUND</b></p>)
                  } */}

                </div>
              </div>




            </div>


          </div>
        </div>
      </div>
      {
        isShowModal && <ChartAccountModal closeModal={hideModal} addRecord={selectedRecord ? false : true} record={selectedRecord} /> || <></>
      }
    </>
  )
}

export default ChartOfAccounts;